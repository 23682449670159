/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@include mat-core();


$mat-adlaw-green:  (
    50 :#dbfce7,
    100 : #c5f7d7,
    200 : #b0f2c7,
    300 : #8be0a9,
    400 : #70d192,
    500 : #50ba75,
    600 : #2e9b54,
    700 : #19873f,
    800 : #086d2b,
    900 : #006121,
    A100 : #c2f5d3,
    A200 : #98ecb4,
    A400 : #3cc469,
    A700 : #088f36,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-adlaw-grey: (
    50 : #e7e7e7,
    100 : #c4c4c4,
    200 : #9d9d9d,
    300 : #767676,
    400 : #585858,
    500 : #3b3b3b,
    600 : #353535,
    700 : #2d2d2d,
    800 : #262626,
    900 : #191919,
    A100 : #ef7272,
    A200 : #ea4444,
    A400 : #fc0000,
    A700 : #e20000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$primary: mat-palette($mat-adlaw-green);
$accent: mat-palette($mat-adlaw-grey);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);

html, body {
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
}

body {
  overscroll-behavior:none;
}

.foot{
    height: 3px;
}

.bg-accent {
    //background-color: #006121 !important;
    //background-color: #088f36 !important;
    //background-color: rgb(59, 59, 59) !important;
    background-color: #000000 !important;
    color:white !important;
}

.bg-mid {
    background-color: rgb(82, 82, 82);
    color:white;
}

.bg-dark {
    background-color: rgb(59, 59, 59);
    color:white;
}

.bg {
    background-color: rgb(250, 250, 250);
    //background-color: rgb(82, 82, 82);
    color:rgba(0,0,0,.87);
}

.mat-button-toggle {
    flex: auto;
    width: 100%;
}

.mat-button-toggle-checked {
    color:white!important;
    background-color:  #088f36 !important;
}


.mat-button-toggle-label-content {
    line-height: 20px !important;
    //padding:12px 0 !important;
}

.mat-simple-snackbar-action .mat-button {
    color: white;
}

.flex {
    display:flex;
    width: 100%;
}

.flex .spacer {
    flex:auto;
}

.flex button {
    margin-left:10px;
}

.control-wrapper {
    display: inline-flex;
    flex-direction: column;
    margin-bottom:12px;
}

.control-wrapper .mat-error {
    font-size: 75%;
}


.mat-pseudo-checkbox {
    color: #088f36 !important;
}



.mat-pseudo-checkbox-checked{
    background: #ffffff !important;
    border: #086d2b;
}


.mat-pseudo-checkbox::after {
    color: #088f36 !important;
}

.clickable-list-item{
    cursor: pointer;
}

.mat-cell {
  color:inherit !important;
}

.mat-drawer-inner-container{
  background-color: rgb(59, 59, 59) !important;
}


button {
  transition: none !important;
  box-shadow: none !important;
}


mat-ink-bar{
  height: 5px !important;
  background-color: #088f36;
}

.mat-tab-labels{
  border-bottom: solid #50ba75;
}

.mat-tab-label{
  font-size: 1.2em !important;
  opacity: 1 !important;
}

.mat-tab-label-active{
  background-color: #50ba75;
  color: white !important;
}
